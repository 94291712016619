@import "../components/color.scss";

.welcome-background {
  background-image: url("../../imgs/backgroun-image.jpg");
  background-size: cover;

  .transparent-color-layer {
    background-color: $blue-600 + aa;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

div.side-login-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;

  flex: 1 1 auto;
  padding: 15% 10%;

  .login-card-header {
    min-height: 36px;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
  }

  .sign-up-card-body {
    flex: 1 1 auto;

    .input-group {
      margin: 5% 0;

      input {
        border-radius: 5px !important;
      }

      svg {
        &.login-icon {
          position: absolute;
          right: 3%;
          top: 30%;
          color: gray;
          z-index: 10;
        }
      }
    }
  }

  .login-card-body {
    flex: 1 1 auto;
    height: 100%;

    .input-group {
      margin: 5% 0;

      input {
        border-radius: 5px !important;
      }

      svg.login-icon {
        position: absolute;
        right: 3%;
        top: 30%;
        color: gray;
        z-index: 10;
      }
    }

    .btn-google-login {
      background-color: $blue-800;
      color: $white;
    }
  }

  .login-card-footer {
    padding: 0.5rem 1rem;
  }
}

h1 {
  &.title {
    color: $primary-color;
  }
}

h6 {
  &.subtitle {
    color: $gray-600;
  }
}

.PhoneInputInput {
  border-width: 0px;
}

.PhoneInputInput:focus {
  outline: 0px;
}

.emailSentTo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-700;
}

.backToLoginBtn {
  stroke: $primary-color;
  stroke-width: 2;
  cursor: pointer;
}
