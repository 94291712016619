.accounts-header {
  box-sizing: border-box;
  align-items: flex-start;
  padding: 16px 24px 0px;

  width: 100%;
  height: 115px;

  border-bottom: 1px solid #e9ecef;
  margin-bottom: 16px;

  .accounts-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .accounts-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $gray-500;

    i {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    button {
      margin: 0px 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      i {
        margin-right: 8px;
      }
    }
  }

  .kpi {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0px 16px;

    flex-wrap: wrap;

    width: 100%;
    height: 100%;

    .kpi-card {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      width: 100%;
      height: 100%;

      span {
        color: $gray-500;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      .account-numbers {
        color: $blue-900;
        font-weight: 450;
        font-size: 14px;
        line-height: 17px;
      }

      .comparition-badge {
        justify-content: center;
        align-items: center;
        padding: 2px 8px;

        width: 52px;
        height: 18px;

        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .accounts-header {
    padding: 8px 12px 0px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .accounts-header {
    padding: 8px 12px 0px;
    height: auto;
  }

  .accounts-title,
  .accounts-subtitle,
  .header-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-buttons button {
    margin: 8px 0px;
  }

  .kpi {
    flex-direction: column;
    padding: 8px;
  }

  .kpi-card {
    width: auto;
    height: auto;
  }
}

.accounts-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 100%;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .nav-link {
    background: #ffffff;
    box-shadow: inset 1px 0px 0px #dfe1e6, inset -1px 0px 0px #dfe1e6,
      inset 0px 1px 0px #e0e2e6;
    border-radius: 4px 4px 0px 0px;

    color: $black;
  }

  .tab-content {
    .top-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;

      width: 100%;
      height: 42px;

      background: #ffffff;

      border-bottom: 1px solid #e9ecef;
      margin: 0px;

      .bar-button {
        color: $primary-color;
        padding: 0px 15px;
        cursor: pointer;
        height: 20px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        i {
          margin: 0px 8px;
          height: 16px;
          width: 16px;
        }
      }

      .search-input {
        padding: 4px 28px;
        height: 26px;

        background: #f8f9fa;

        border: 1px solid #e8f1fc;
        border-radius: 32px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #adb5bd;
      }

      .search-icon {
        position: relative;
        left: 20px;
        z-index: 10;
        color: #adb5bd;
        font-size: 12px;
      }
    }

    .tab-content-body {
      padding: 16px;
      margin: 0px;

      width: 100%;

      background: #ffffff;

      .table-striped > tbody > tr:nth-child(odd) > td {
        background-color: #f8f9fa !important;
      }

      .table-hover tbody tr:hover td {
        background: $blue-100 !important;
      }

      td {
        font-weight: 510;
        font-size: 12px;
        line-height: 13px;

        i {
          color: "#CED4DA";
          cursor: pointer;
        }

        .category-cell {
          display: flex;
          flex-direction: row;

          .category-symbol {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 510;
            font-size: 10px;
            line-height: 12px;
            border-radius: 50%;

            margin-right: 8px;

            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
