.step-item {
  @apply relative flex flex-col justify-center items-center w-full;
}

.step-item:not(:first-child)::before {
  @apply content-[''] bg-gray-200 absolute w-full h-[3px] rounded-full right-2/4 top-1/3 -translate-y-2/4;
}

.step {
  @apply w-14 h-14 flex items-center justify-center z-10 relative bg-gray-200 text-gray-500 rounded-full font-semibold;
}

.active .step {
  @apply bg-white border-2 border-primary border-solid text-primary;
}

.complete .step {
  @apply bg-primary text-white;
}

.complete span {
  @apply text-primary;
}
