.settings-title {
  font-weight: 274;
  font-size: 20px;
  line-height: 30px;
  font-feature-settings: "liga" off;
  color: $gray-600;
}

.settings-subtitle {
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "liga" off;

  color: $gray-600;
}

.select-profile-pic {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
}

form {
  color: #212529;

  label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.32px;
  }

  input,
  button {
    padding: 7px 12px;

    height: 32px;

    background: #ffffff;
    border: 1px solid #cfd4d9;
    box-shadow: 0px 0px 0px #cbdafc;
    border-radius: 4px;

    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;

    .show-password {
      background-color: #ffffff !important;
      border: 0 !important;
    }
  }
}
