.top-bar-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: $blue-100;
  padding: 16px 32px;
  height: 72px !important;
  width: 100%;

  .search-bar {
    display: flex;
  }

  .buttons-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      margin: 0px 8px;
      height: 40px;

      .dropdown {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-600;

        button {
          background: transparent;
          border: 0;
          height: 40px;
          padding: 4px;
        }

        div {
          padding: 4px;
          button {
            margin: 4px 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $gray-600;
          }
        }
      }
    }

    .whatsapp-button {
      background-color: $white;
      color: $gray-500;
      border: 0;
    }

    .notification-button {
      position: relative;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      user-select: none;
      bottom: -12px;

      .notification-badge {
        border-radius: 30%;
        padding: 2px 10px;
        font-size: 12px;
        display: block;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -10px;
        right: -15px;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;

    font-weight: 274;
    font-size: 20px;
    line-height: 30px;
    color: $primary-color;

    gap: 8px;
  }

  .back-button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-600;
    margin-right: 16px;
    text-decoration: none;
  }
}
