.budget-header {
  box-sizing: border-box;
  align-items: flex-start;
  padding: 24px;

  width: 100%;
  height: 115px;

  border-bottom: 1px solid #e9ecef;
  margin-bottom: 16px;
  background-color: $light-color-brand;

  .row.budget-header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    height: 100%;
    width: 100%;

    .icon {
      display: flex;
      align-content: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: $primary-color;
      border-radius: 50%;

      i {
        transform: rotate(45deg);
        margin: auto;
        color: $blue-100;
        font-size: 24px;
      }
    }

    .title-container {
      .title {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }

        i {
          margin-left: 8px;
          color: $gray-500;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .add-note {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        color: $gray-500;
        cursor: pointer;
      }
    }
  }

  .row.budget-header-date {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: stretch;

    height: 100%;
    width: 100%;

    .row {
      display: flex;
      align-content: center;
      justify-content: center;

      .date-picker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 100%;

        .react-datepicker-wrapper,
        .react-datepicker__input-container,
        .react-datepicker__input-container input {
          display: block;
          width: 150px;
          height: 26px;
          color: $primary-color;

          font-weight: 510;
          font-size: 16px;
          line-height: 19px;
          border: 0px;

          background: transparent;

          text-align: center;
        }

        .change-date-button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          height: 26px;
          color: $primary-color;

          background: transparent;

          text-align: center;
          border: 0px;
        }
      }
    }
  }

  .row.budget-header-budget {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    height: 100%;
    width: 100%;

    .budget-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      height: 100%;
      width: 100%;

      .assign-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 107px;
        height: 38px;
        margin-right: 2px;
      }

      .budget-amount {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $success-color;
        padding: 8px 16px;
        width: auto;
        height: 38px;
        background: #1987540f;
        border-radius: 4px;
        margin-left: 2px;
      }
    }

    .row > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      font-feature-settings: "liga" off;

      color: $gray-500;

      text-align: right;

      padding: 0;
    }
  }
}

.budget-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 100%;
  height: 100%;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;

    width: 100%;
    height: 42px;

    background: #ffffff;

    border-bottom: 1px solid #e9ecef;
    margin: 0px;

    .bar-button {
      color: $primary-color;
      padding: 0px 15px;
      cursor: pointer;
      height: 20px;

      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      i {
        margin: 0px 8px;
        height: 16px;
        width: 16px;
      }
    }

    .search-input {
      padding: 4px 28px;
      height: 26px;

      background: #f8f9fa;

      border: 1px solid #e8f1fc;
      border-radius: 32px;

      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #adb5bd;
    }

    .search-icon {
      position: relative;
      left: 20px;
      z-index: 10;
      color: #adb5bd;
      font-size: 12px;
    }
  }

  .tab-content-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;

    width: 100%;
    height: 100%;

    background: #ffffff;

    border-bottom: 1px solid #e9ecef;
    margin: 0px;

    table {
      thead {
        color: $gray-900;
        font-weight: 510;
        font-size: 14px;
        line-height: 18px;
        height: 50px;
        vertical-align: middle;
      }

      tbody {
        color: $gray-900;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        span.average {
          color: $gray-600;
          font-weight: 400;
          font-size: 10px;
          line-height: 18px;
        }
      }
    }
  }

  /* tr.category-group {
    td {
      font-weight: 510;
      font-size: 12px;
      line-height: 18px;
    }
  }

  tr.subcategory {
    td {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;

      .category-description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 8px 16px;
      }
    }
  } */
}
