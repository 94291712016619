.budget-monthpicker {
  .ant-picker {
    width: 100%;

    .ant-picker-input > input {
      text-transform: uppercase !important;
      color: #2c7be5 !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      text-align: center !important;
    }
  }
}

.new-budget-monthpicker {
  .ant-picker {
    width: 100%;

    .ant-picker-input > input {
      text-transform: capitalize !important;
      text-align: center !important;
    }
  }
}
