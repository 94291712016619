.setting-layout {
  max-height: calc(100vh - 72px);

  margin-top: 0;
  top: 72px;
  overflow: scroll;
  box-shadow: 0px 2px 4px rgba(65, 69, 88, 0.1);
  background-color: $light-color-brand;

  &.container {
    padding: 0;
  }

  .settings-header {
    height: 92px;
    font-weight: 274;
    font-size: 24px;
    line-height: 28px;

    padding: 32px 48px;

    color: #6c757d;
  }

  .settings-body-container {
    padding: 0;
    display: flex;
    flex-direction: row;

    .settings-page-body {
      position: relative;
      min-width: 100%;
      min-height: 100%;
    }
  }
}
