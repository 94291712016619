// Colors and Shades
$blue-100: #e8f1fc;
$blue-200: #bbd4f7;
$blue-300: #8db8f1;
$blue-400: #609ceb;
$blue-500: #337fe6;
$blue-600: #1966cc;
$blue-700: #0e3872;
$blue-800: #0e3872;
$blue-900: #082244;

$indigo-100: #e0cffc;
$indigo-200: #c29ffa;
$indigo-300: #a370f7;
$indigo-400: #8540f5;
$indigo-500: #6610f2;
$indigo-600: #520dc2;
$indigo-700: #3d0a91;
$indigo-800: #290661;
$indigo-900: #140330;

$purple-100: #e2d9f3;
$purple-200: #c5b3e6;
$purple-300: #a98eda;
$purple-400: #8c68cd;
$purple-500: #6f42c1;
$purple-600: #59359a;
$purple-700: #432874;
$purple-800: #2c1a4d;
$purple-900: #160d27;

$red-100: #f8d7da;
$red-200: #f1aeb5;
$red-300: #ea868f;
$red-400: #e35d6a;
$red-500: #dc3545;
$red-600: #b02a37;
$red-700: #842029;
$red-800: #58151c;
$red-900: #2c0b0e;

$orange-100: #ffe5d0;
$orange-200: #fecba1;
$orange-300: #feb272;
$orange-400: #fd9843;
$orange-500: #fd7e14;
$orange-600: #ca6510;
$orange-700: #984c0c;
$orange-800: #331904;
$orange-900: #653208;

$yellow-100: #fff3cd;
$yellow-200: #ffe69c;
$yellow-300: #ffda6a;
$yellow-400: #ffcd39;
$yellow-500: #ffc107;
$yellow-600: #cc9a06;
$yellow-700: #997404;
$yellow-800: #664d03;
$yellow-900: #332701;

$green-100: #d1e7dd;
$green-200: #a3cfbb;
$green-300: #75b798;
$green-400: #479f76;
$green-500: #198754;
$green-600: #146c43;
$green-700: #0f5132;
$green-800: #0a3622;
$green-900: #051b11;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
