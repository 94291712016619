:root {
  --theme-deafult: #2c7be5;
  --theme-secondary: #6c757d;
  --theme-success: #198754;
  --theme-danger: #dc3545;
  --theme-warning: #ffc107;
  --theme-info: #0dcaf0;
  --theme-light: #f8f9fa;
  --theme-dark: #212529;
}

$primary-color-brand: #2c7be5;
$secondary-color-brand: #6c757d;
$success-color-brand: #198754;
$danger-color-brand: #dc3545;
$warning-color-brand: #ffc107;
$info-color-brand: #0dcaf0;
$light-color-brand: #f8f9fa;
$dark-color-brand: #212529;
