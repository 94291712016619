#form-file-upload {
  @apply flex flex-col w-full h-full justify-center items-center
  /* height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative; */;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  @apply flex flex-col w-full h-full justify-center items-center border-2 border-dashed border-gray-500 rounded-lg gap-4;

  &.drag-active {
    background-color: #ffffff;
  }
  /* height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc; */
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
